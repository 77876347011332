<template>
  <form class="first-step" :class="platform" @submit.prevent="handleSubmit">
    <NavigationTabs :type="platform" />
    <div class="content">
      <h2 v-if="platform !== 'mobile'">{{ t('payments.buyCrypto.title') }}</h2>
      <CurrencySelect v-model="currencyId" :platform="platform" />
      <NetworkSelect
        v-model="networkId"
        :networks="networks"
        :platform="platform"
      />
      <div class="disclaimer">
        {{ t('payments.buyCrypto.disclaimer') }}
      </div>
    </div>
    <div class="button-wrapper">
      <StButton
        :loading="status === 'pending'"
        :size="platform === 'desktop' ? 'xl' : 'l'"
        :disabled="!networkId"
        :label="t('payments.buyCrypto.buy')"
        class="buy-button"
        submit
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { useCurrencies } from '../../../stores/currencies'

const emit = defineEmits<{
  (
    e: 'goToNextStep',
    value: { key: string; address: string; currencyCode: string },
  ): void
}>()

withDefaults(
  defineProps<{
    platform: 'mobile' | 'desktop'
  }>(),
  { platform: 'desktop' },
)

const { t } = useI18n()

const currenciesStore = useCurrencies()
const { currencies } = storeToRefs(currenciesStore)

const currencyId = ref(1)

const networkId = ref()
const networks = computed(
  () => currencies.value[currencyId.value].networks || [],
)

watch(currencyId, () => {
  networkId.value = ''
})

const { data, execute, status } = useStFetch('/deposit/address/get', {
  method: 'POST',
  immediate: false,
  watch: false,
  body: computed(() => ({
    currencyId: currencyId.value,
    networkId: networkId.value,
  })),
})

async function handleSubmit() {
  await execute()

  if (
    status.value === 'success' &&
    data.value?.address &&
    data.value?.mercuryoSignature
  ) {
    emit('goToNextStep', {
      address: data.value?.address,
      key: data.value?.mercuryoSignature,
      currencyCode: currencies.value[currencyId.value].code,
    })
  }
}
</script>

<style scoped>
.first-step {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  height: 100%;
  margin-top: var(--spacing-300);
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);
  justify-content: flex-start;

  height: 100%;
}

h2 {
  margin: var(--spacing-300) 0 var(--spacing-200);
  font: var(--desktop-text-2xl-semibold);
}

.disclaimer {
  margin-top: var(--spacing-100);
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}

.buy-button {
  width: 100%;
}

.first-step.mobile {
  margin-top: 0;

  .content {
    padding: var(--spacing-200);
  }

  .button-wrapper {
    padding: var(--spacing-200);
  }

  .disclaimer {
    font: var(--mobile-text-content-regular);
  }
}
</style>
