<template>
  <div :class="platform" class="deposit-bonus" data-t="deposit-bonus-banner">
    <div class="content">
      <div class="left-side">
        <div class="bonus-title">
          <div>{{ leftTitle }}</div>
          <StTooltip v-if="platform === 'desktop'">
            <template #activator>
              <img class="tooltip" src="./assets/tooltip.svg" />
            </template>
            <DepositBonusTooltip :bonus="props.bonus" />
          </StTooltip>
        </div>
        <div class="bonus-amount">{{ depositMultiplier }}</div>
      </div>
      <div class="right-side">
        <div class="min-deposit-title">{{ rightTitle }}</div>
        <div v-if="type === 'deposit'" class="min-deposit-amount">
          {{ minDeposit }}
          <span>
            ( ~{{ cryptoMinAmount }}
            <StIcon :name="selectedCurrencyIcon" size="16" />)
          </span>
        </div>
        <div v-else class="min-deposit-amount">
          {{ cryptoMinAmount }}
          <StIcon
            :name="selectedCurrencyIcon"
            :size="platform === 'desktop' ? 20 : 16"
          />
        </div>
        <div class="bonus-expiration">
          <span v-if="platform === 'desktop'">
            {{ t('bonuses.bonusExpires') }}
          </span>
          <BonusTimer
            v-if="bonus.expiredAt"
            size="s"
            :is-mobile="platform === 'mobile'"
            :expires-at="bonus.expiredAt"
          />
        </div>
        <div v-if="platform === 'mobile'" class="mobile-tooltip">
          <StTooltip>
            <template #activator>
              <img class="tooltip" src="./assets/tooltip.svg" />
            </template>
            <DepositBonusTooltip :bonus="props.bonus" />
          </StTooltip>
        </div>

        <div class="chest">
          <img v-if="platform === 'desktop'" src="./assets/chest.svg" />
          <img v-else src="./assets/chest-small.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useConverterStore } from '@st/payments/stores/useConverterStore'
import { useCurrencies } from '@st/payments/stores/currencies'
import BonusTimer from '../FreeBonusCard/parts/BonusTimer.vue'
import DepositBonusTooltip from '../DepositBonusTooltip/DepositBonusTooltip.vue'
import type { DepositBonus } from '../../types'
import { useDepositBonus } from '../../composables/useDepositBonus'

const props = withDefaults(
  defineProps<{
    bonus: DepositBonus
    selectedCurrencyCode: string
    selectedCurrencyIcon: string
    platform: 'desktop' | 'mobile'
    type: 'deposit' | 'buy'
  }>(),
  {
    type: 'deposit',
  },
)

const { t } = useI18n()

const { bonus } = toRefs(props)

const { appCurrency } = storeToRefs(useCurrencies())
const { convert } = useConverterStore()

const { format: formatCrypto } = useCryptoFormatter()

const cryptoMinAmount = computed(() =>
  formatCrypto(
    convert(props.bonus.minDepositAmountInAppCurrency, {
      from: appCurrency.value.code,
      to: props.selectedCurrencyCode,
    }),
  ),
)

const { bonusMultiplier: depositMultiplier, minDepositAmount: minDeposit } =
  useDepositBonus(bonus)

const leftTitle = computed(() =>
  props.type === 'deposit' ? t('bonuses.depositTitle') : t('bonuses.buyTitle'),
)
const rightTitle = computed(() =>
  props.type === 'deposit' ? t('bonuses.depositMinShort') : t('bonuses.buyMin'),
)
</script>

<style scoped>
.deposit-bonus {
  display: flex;

  min-height: 104px;

  background: radial-gradient(
      102.33% 102.33% at 50% 19.65%,
      rgb(253 46 0 / 70%) 0%,
      rgb(232 0 42 / 7%) 85.92%
    ),
    #20202a;
  border-radius: var(--border-radius-150, 12px);
  box-shadow: 0 4px 40px 0 rgb(252 57 104 / 40%) inset;
}

.content {
  display: flex;
  width: 100%;
  padding: var(--spacing-125) var(--spacing-200) var(--spacing-150);
}

.left-side {
  width: 260px;
  padding-right: var(--spacing-150);
}

.tooltip {
  cursor: pointer;
}

.right-side {
  position: relative;
  width: 100%;
  padding-left: var(--spacing-250);
  border-left: 1px solid rgb(255 255 255 / 8%);
}

.bonus-title {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
  font: var(--desktop-text-md-semibold);
}

/* stylelint-disable */
.bonus-amount {
  font-family: 'SF Pro Display';
  font-size: 40px;
  font-weight: 600;
  font-style: normal;
  line-height: 40px;
  text-shadow: 0 4px 60px rgb(249 218 144 / 32%);

  background: linear-gradient(
    109deg,
    #ffe86d 17.82%,
    #f8db90 42.56%,
    #feb581 72.33%,
    #f09f7c 81.52%
  );
  background-clip: text;
  background-clip: text;

  -webkit-text-fill-color: transparent;
}
/* stylelint-enable */

.min-deposit-title {
  width: fit-content;
  font: var(--desktop-text-xs-medium);
}

.min-deposit-amount {
  display: flex;
  gap: var(--spacing-075);
  align-items: baseline;
  font: var(--desktop-text-xl-semibold);

  span {
    display: flex;
    gap: var(--spacing-050);
    align-items: center;

    font: var(--desktop-text-md-medium);
    line-height: 0;
    color: var(--text-secondary);
  }
}

.bonus-expiration {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;

  width: fit-content;
  margin-top: var(--spacing-100);
  padding: var(--spacing-050) var(--spacing-050) var(--spacing-050)
    var(--spacing-100);

  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary, rgb(255 255 255 / 64%));

  background: rgb(255 255 255 / 8%);
  border-radius: var(--border-radius-075, 6px);
}

.chest {
  position: absolute;
  right: -30px;
  bottom: -30px;
}

.mobile.deposit-bonus {
  width: auto;
  min-height: 84px;

  .content {
    padding: var(--spacing-125) var(--spacing-125) var(--spacing-125)
      var(--spacing-150);
  }

  .left-side {
    max-width: 135px;
  }

  .right-side {
    padding-left: var(--spacing-200);
  }

  .bonus-title {
    font: var(--mobile-caption-1-medium);
  }

  .min-deposit-title {
    font: var(--mobile-caption-1-medium);
  }

  .min-deposit-amount {
    font: var(--mobile-headline-semibold);

    span {
      font: var(--mobile-caption-1-regular);
    }
  }

  .bonus-amount {
    /* stylelint-disable */
    font-size: 28px;
    line-height: 32px;
    /* stylelint-enable */
  }

  .chest {
    right: -20px;
    bottom: -23px;
  }

  .mobile-tooltip {
    position: absolute;
    top: 0;
    right: 0;
  }

  .bonus-expiration {
    margin-top: var(--spacing-075);
    padding: var(--spacing-025) var(--spacing-075);
    font: var(--desktop-text-xs-medium);
  }
}
</style>
