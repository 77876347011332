<template>
  <form
    class="withdrawal-form"
    :class="platform"
    data-t="withdrawal-form"
    @submit.prevent="handleNextStep"
  >
    <h2 v-if="platform === 'desktop'">
      {{ t('payments.withdrawal.withdraw') }}
    </h2>
    <CurrencySelect
      v-model="currencyId"
      :platform="platform"
      operation-type="withdrawal"
    />
    <NetworkSelect
      v-model="networkId"
      :networks="networks"
      :platform="platform"
      operation-type="withdrawal"
    />
    <AddressSelect
      v-model="addressValue"
      :items="mappedAddresses"
      :platform="platform"
      :action-button-text="t('payments.withdrawal.addNewWalletAddress')"
      :error-message="validationErrorMessage"
      data-t="addresses"
      action-button-prefix-icon="plus"
      @handle-dropdown-click="handleAddNewAddress"
    />
    <div class="sum">
      <StInput
        v-model="rawAmount"
        inputmode="decimal"
        :placeholder="amountPlaceholder"
        :label="t('payments.withdrawal.amount')"
        :size="platform === 'mobile' ? 'm' : 'l'"
        data-t="amount"
        :error-message="inputErrorMessage"
        :error="!!inputErrorMessage"
        @update:model-value="onDecimalInputUpdate"
      >
        <template #postfix>
          <div class="right-area">
            <div class="selected-currency-code">
              {{ selectedCurrency.code }}
            </div>
            <StButton
              label="MAX"
              type="ghost"
              size="s"
              class="max-button"
              data-t="max-button"
              @click="maxAmountClick"
            />
          </div>
        </template>
      </StInput>
      <DepositRollingInfo
        v-if="isShownRollingInfo"
        :rolling-amount="rollingAmount"
        :currency-code="selectedCurrency?.code"
        :rolled-withdrawal-amount="rolledWithdrawalAmount"
        :platform="platform"
        class="deposit-rolling-info"
      />
      <StTransitionExpand>
        <WithdrawalSummary
          v-if="amount && !isZeroAmount"
          :platform="platform"
          :currency-code="selectedCurrency?.code"
          :amount="amount"
          :fee="fee"
          :fee-type="feeType"
          :final-amount="finalAmount"
          :is-shown-rolling-info="isShownRollingInfo"
          :rolling-amount="rollingAmount"
          class="summary"
        />
      </StTransitionExpand>
      <div class="button-wrapper">
        <StButton
          :label="t('payments.withdrawal.withdraw')"
          class="withdrawal-button"
          data-t="withdrawal-button"
          :size="platform === 'desktop' ? 'xl' : 'l'"
          submit
        />
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import { isValidNumber } from '@st/utils'
import { useAccountsStore } from '../../../stores/useAccountsStore'
import { useCurrencies } from '../../../stores/currencies'
import { useAddressesStore } from '../../../stores/useAddressesStore'
import { useTariffsStore } from '../../../stores/useTariffsStore'
import { useValidateAddress } from '../../../composables/useValidateAddress'
import { useDepositRolling } from '../composables/useDepositRolling'
import type { WithdrawalData } from '../../../interfaces'
import AddressSelect from '../../AddressSelect/AddressSelect.vue'
import DepositRollingInfo from './DepositRollingInfo.vue'
import WithdrawalSummary from './WithdrawalSummary.vue'
import { useWithdrawalFee } from '../composables/useWithdrawalFee'

const currenciesStore = useCurrencies()
const accountsStore = useAccountsStore()

const { currencies } = storeToRefs(currenciesStore)
const { realAccounts } = storeToRefs(accountsStore)
const { addresses: userAddresses } = storeToRefs(useAddressesStore())
const {
  validateAddress,
  validationErrorMessage,
  networkId: validationNetworkId,
  address: validationAddress,
} = useValidateAddress()

const { format: formatCrypto } = useCryptoFormatter()

const emit = defineEmits<{
  (e: 'back'): void
  (e: 'handleNextStep', data: WithdrawalData): void
  (
    e: 'addNewAddress',
    data: {
      selectedCurrencyId?: number
      selectedNetworkId?: number
      address?: string
    },
  ): void
}>()

const props = withDefaults(
  defineProps<{
    selectedCurrencyId: number
    selectedNetworkId?: number
    selectedAddressName?: string
    initialAmount?: string
    platform: 'desktop' | 'mobile'
  }>(),
  {
    selectedCurrencyId: 1,
    platform: 'desktop',
  },
)

const { t } = useI18n()

const currencyId = ref(props.selectedCurrencyId)
const selectedAccount = computed(() =>
  realAccounts.value.find((account) => account.currencyId === currencyId.value),
)
const selectedCurrency = computed(() => currencies.value[currencyId.value])
watch(
  () => props.selectedCurrencyId,
  (value) => {
    currencyId.value = value
  },
)

const networks = computed(
  () => currencies.value[currencyId.value]?.networks ?? [],
)

const networkId = ref(props.selectedNetworkId)
watch(
  () => props.selectedNetworkId,
  (value) => {
    networkId.value = value
  },
)

watch(currencyId, () => {
  if (networks.value.length === 1) {
    networkId.value = networks.value[0].id
  } else {
    networkId.value = undefined
  }
})

const selectedNetwork = computed(
  () => networks.value?.find((network) => network.id === networkId.value),
)

const findPaymentAddressBody = computed(() => ({
  currencyId: currencyId.value,
  networkId: networkId.value,
}))

const { data: addresses, execute: findPaymentAddress } = useStFetch(
  '/payment-address/find',
  {
    method: 'POST',
    immediate: false,
    watch: false,
    body: findPaymentAddressBody,
  },
)

watch(
  findPaymentAddressBody,
  (body) => {
    if (body.currencyId && body.networkId) findPaymentAddress()
  },
  { immediate: true },
)

const addressValue = ref(props.selectedAddressName)
const mappedAddresses = computed(
  () =>
    addresses.value?.map((address) => ({
      ...address,
      hiddenValue: address.address,
      label: !address.twoFactorAuthRequired
        ? t('payments.addressBook.confirmed')
        : '',
    })) || [],
)

const rawAmount = ref<string>(props.initialAmount ?? '')

const { findLimit, findMinimalLimit } = useTariffsStore()

const limit = computed(() => {
  if (!currencyId.value) return undefined

  if (!networkId.value) {
    return findMinimalLimit({
      operationType: 'withdrawal',
      currencyId: currencyId.value,
    })
  }

  return findLimit({
    currencyId: currencyId.value,
    networkId: networkId.value,
    operationType: 'withdrawal',
  })
})

const amountPlaceholder = computed(() => {
  if (!limit.value) return ''

  return t('payments.withdrawal.insertAmountPlaceholder', {
    amount: formatCrypto(limit.value?.minAmount),
    currency: selectedCurrency.value.code,
  })
})

const amount = computed(() => {
  const formattedAmount = rawAmount.value.replace(',', '.')
  if (!isValidNumber(formattedAmount)) return '0'

  return formattedAmount
})

const isZeroAmount = computed(() => new Decimal(amount.value).isZero())

const { rolledWithdrawalAmount, rollingAmount, isShownRollingInfo } =
  useDepositRolling({ currencyId })

const { feeAmount: fee, feeType } = useWithdrawalFee({
  amount,
  networkId,
  currencyId,
  rolledWithdrawalAmount,
  isEnabledRolling: isShownRollingInfo,
})

function maxAmountClick() {
  const maxAmount = Decimal.min(
    limit.value?.maxAmount || 0,
    selectedAccount.value?.balance || 0,
  )

  rawAmount.value = maxAmount.toString()
}

const finalAmount = computed(() => {
  const value = new Decimal(amount.value || 0).minus(fee.value)

  return Decimal.max(0, value).toString()
})

const inputErrorMessage = computed(() => {
  if (!selectedAccount.value) return undefined
  if (!rawAmount.value) return undefined

  const formattedAmount = rawAmount.value.replace(',', '.')

  if (!isValidNumber(formattedAmount)) {
    return t('payments.withdrawal.validationError')
  }

  const decimalAmount = new Decimal(formattedAmount)

  const isNegativeAmount = decimalAmount.lessThanOrEqualTo(fee.value)

  if (isNegativeAmount) {
    return t('payments.withdrawal.negativeAmount')
  }

  const isBelowMinimum =
    limit.value?.minAmount && decimalAmount.lessThan(limit.value.minAmount)
  const isAboveMaximum =
    limit.value?.maxAmount && decimalAmount.greaterThan(limit.value.maxAmount)

  if (isBelowMinimum || isAboveMaximum) {
    return t('payments.withdrawal.withdrawalIsForbiddenByAmount')
  }

  const isAboveBalance =
    selectedAccount.value?.balance &&
    decimalAmount.greaterThan(selectedAccount.value?.balance)

  if (isAboveBalance) {
    return t('payments.withdrawal.accountNegativeError')
  }

  return undefined
})

async function handleNextStep() {
  if (!selectedNetwork.value) return
  if (!addressValue.value) return
  if (inputErrorMessage.value) return
  if (isZeroAmount.value) return

  const foundAddress = userAddresses.value.find(
    (address) => address.address === addressValue.value,
  )

  validationAddress.value = addressValue.value
  validationNetworkId.value = networkId.value

  await validateAddress()

  if (!validationErrorMessage.value) {
    emit('handleNextStep', {
      amount: amount.value,
      address: addressValue.value,
      commission: fee.value,
      finalAmount: finalAmount.value,
      network: selectedNetwork.value,
      currencyCode: selectedCurrency.value.code,
      selectedCurrencyId: currencyId.value,
      selectedNetworkId: networkId.value,
      twoFactorAuthRequired: foundAddress?.twoFactorAuthRequired,
      isNewAddress: !foundAddress,
    })
  }
}

function handleAddNewAddress() {
  emit('addNewAddress', {
    selectedCurrencyId: currencyId.value,
    selectedNetworkId: networkId.value,
    address: addressValue.value,
  })
}

function onDecimalInputUpdate() {
  rawAmount.value = rawAmount.value.replace(',', '.')
}
</script>

<style scoped>
.withdrawal-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);

  min-width: 548px;

  h2 {
    margin: var(--spacing-400) 0 0;
    font: var(--desktop-text-2xl-semibold);
  }
}

.right-area {
  display: flex;
  align-items: center;

  .selected-currency-code {
    padding-right: var(--spacing-150);
    font: var(--desktop-text-md-medium);
    color: var(--text-tertiary);
    border-right: 1px solid var(--border-primary);
  }

  .max-button {
    margin-left: var(--spacing-150);
  }
}

.sum {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-self: flex-end;

  height: 100%;
}

.button-wrapper {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;

  width: 100%;
  margin-top: var(--spacing-200);
}

.withdrawal-button {
  width: 100%;
}

.withdrawal-form.mobile {
  overflow-y: scroll;

  width: 100%;
  min-width: auto;
  height: 100%;
  min-height: auto;
  padding: var(--spacing-200) var(--spacing-200) 0;

  .button-wrapper {
    flex-grow: 1;
    padding: var(--spacing-250) 0 var(--spacing-200);
  }

  .selected-currency-code {
    font: var(--desktop-text-sm-medium);
  }
}

.deposit-rolling-info {
  margin-top: var(--spacing-250);
}

.summary {
  margin-top: var(--spacing-200);
}
</style>
