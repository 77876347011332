<template>
  <article class="withdrawal-summary" :class="platform">
    <div class="group">
      <div class="group-label">
        {{ t('payments.withdrawal.withdrawalAmount') }}
      </div>
      <div class="group-value">
        <div class="value" data-t="crypto-amount">
          {{ formatCrypto(amount) }}
        </div>
        <div class="subvalue" data-t="fiat-amount">
          ~{{ formatFiat(fiatAmount) }}
        </div>
      </div>
    </div>
    <div class="group">
      <div class="group-label">
        <span class="fee">
          {{ t('payments.withdrawal.commission') }}
          <template v-if="!isZeroFee && isShownRollingInfo">
            <StTooltip v-if="feeType === 'withdrawal'" placement="top">
              <template #activator>
                <div class="fee-label green" data-t="fee-label">
                  {{ t('payments.withdrawal.feeMinimal') }}
                  <StIcon name="circle-info" size="16" class="fee-label-icon" />
                </div>
              </template>
              <div class="tooltip-content">
                {{ t('payments.withdrawal.feeMinimalTooltip') }}
              </div>
            </StTooltip>
            <StTooltip v-else placement="top">
              <template #activator>
                <div class="fee-label yellow" data-t="fee-label">
                  {{ t('payments.withdrawal.feeIncreased') }}
                  <StIcon name="circle-info" size="16" class="fee-label-icon" />
                </div>
              </template>
              <div class="tooltip-content">
                <I18nT keypath="payments.withdrawal.feeIncreasedTooltip">
                  <template #amount>
                    <b>{{ formatCrypto(rollingAmount ?? '0') }}</b>
                  </template>
                </I18nT>
              </div>
            </StTooltip>
          </template>
        </span>
      </div>
      <div class="group-value">
        <div class="value" data-t="crypto-fee">{{ formatCrypto(fee) }}</div>
        <div class="subvalue" data-t="fiat-fee">~{{ formatFiat(fiatFee) }}</div>
      </div>
    </div>
    <hr class="separator" />
    <div class="group">
      <div class="group-label">
        {{ t('payments.withdrawal.finalAmount') }}
      </div>
      <div class="group-value">
        <div class="value" data-t="crypto-final-amount">
          {{ formatCrypto(finalAmount) }}
        </div>
        <div class="subvalue" data-t="fiat-final-amount">
          ~{{ formatFiat(fiatFinalAmount) }}
        </div>
      </div>
    </div>
  </article>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import { useCurrencies } from '../../../stores/currencies'
import { useConverterStore } from '../../../stores/useConverterStore'

const props = withDefaults(
  defineProps<{
    amount: string
    currencyCode: string
    fee: string
    feeType: 'withdrawal' | 'withdrawalUnrolled'
    finalAmount: string
    isShownRollingInfo?: boolean
    platform?: 'mobile' | 'desktop'
    rollingAmount?: string
  }>(),
  {
    platform: 'desktop',
    isShownRollingInfo: false,
  },
)

const { t } = useI18n()

const { appCurrency } = storeToRefs(useCurrencies())

const { format: formatFiat } = useCurrencyFormatter({
  currency: () => appCurrency.value.code,
})
const { format: formatCrypto } = useCryptoFormatter({
  currency: () => props.currencyCode,
  signDisplay: 'never',
})

const { convert } = useConverterStore()

const isZeroFee = computed(() => new Decimal(props.fee).isZero())

const fiatFee = computed(() =>
  convert(props.fee, {
    from: props.currencyCode,
    to: appCurrency.value.code,
  }),
)

const fiatAmount = computed(() =>
  convert(props.amount, {
    from: props.currencyCode,
    to: appCurrency.value.code,
  }),
)

const fiatFinalAmount = computed(() =>
  convert(props.finalAmount, {
    from: props.currencyCode,
    to: appCurrency.value.code,
  }),
)
</script>

<style scoped>
.withdrawal-summary {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);

  padding: var(--spacing-200) var(--spacing-150);

  border: 1px solid var(--border-secondary);
  border-radius: var(--border-radius-100);
}

.group {
  display: flex;
  gap: var(--spacing-200);
}

.group-label {
  flex-shrink: 0;
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.fee {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
}

.group-value {
  flex-grow: 1;
  text-align: right;
}

.value {
  font: var(--desktop-text-sm-semibold);
}

.subvalue {
  font: var(--desktop-text-xs-semibold);
  color: var(--text-tertiary);
}

.separator {
  all: unset;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: var(--spacing-050) 0;

  &::after {
    content: '';
    width: 100%;
    border-top: 1px solid var(--border-secondary);
  }
}

.fee-label {
  display: inline-flex;
  gap: var(--spacing-025);
  align-items: center;
  justify-content: center;

  padding: var(--border-width-medium) var(--spacing-050)
    var(--border-width-medium) var(--spacing-075);

  font: var(--desktop-text-xs-medium);

  border-radius: var(--border-radius-full);

  &.yellow {
    color: var(--text-warning);
    background: rgb(255 209 95 / 12%);
  }

  &.green {
    color: var(--text-success);
    background: rgb(83 198 107 / 12%);
  }
}

.tooltip-content {
  max-width: 240px;
  padding: var(--spacing-150) var(--spacing-200);
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);

  b {
    font: var(--desktop-text-sm-medium);
    color: var(--text-primary);
  }
}

.mobile {
  .group-label {
    font: var(--mobile-text-regular);
  }

  .value {
    font: var(--mobile-text-semibold);
  }

  .subvalue {
    font: var(--mobile-caption-1-regular);
  }

  .fee-label {
    font: var(--mobile-caption-1-medium);
  }
}
</style>
