<template>
  <div class="second-step" :class="platform">
    <div class="back-button-wrapper">
      <StButton
        :label="t('payments.withdrawal.goBack')"
        size="l"
        type="text-only"
        icon="chevron-left"
        is-left-icon
        @click="emit('goBack')"
      />
    </div>
    <div class="frame-container">
      <iframe
        v-show="!isLoadingFrame"
        :src="frameUrl"
        frameborder="no"
        @load="isLoadingFrame = false"
      />
      <div v-if="isLoadingFrame" class="frame-loader">
        <StSpinner size="32" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: 'goBack'): void
}>()

const isLoadingFrame = ref(true)

withDefaults(
  defineProps<{
    platform: 'mobile' | 'desktop'
    frameUrl: string
  }>(),
  {
    platform: 'desktop',
  },
)

const { t } = useI18n()
</script>

<style scoped>
.second-step {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;

  width: 100%;
  min-height: 730px;
  padding-top: var(--spacing-300);
}

.frame-container {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  min-height: 0;
}

.back-button-wrapper {
  margin-bottom: var(--spacing-300);
  line-height: 0;
}

iframe {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  border: 0;
}

.frame-loader {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.second-step.mobile {
  min-height: auto;
  margin-top: 0;
  padding: var(--spacing-200);
}
</style>
