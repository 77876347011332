<template>
  <form class="first-step" :class="platform" @submit.prevent="handleSubmit">
    <NavigationTabs :type="platform" />
    <div class="content">
      <div class="content-top">
        <h2>{{ t('payments.buyCrypto.title') }}</h2>
        <div class="top-info">
          <div class="info-block">
            <div class="image">
              <img src="../assets/balance.png" width="30" />
            </div>
            <div class="text">
              {{
                platform === 'desktop'
                  ? t('payments.buyCrypto.topInfo1')
                  : t('payments.buyCrypto.mTopInfo1')
              }}
            </div>
          </div>
          <div class="info-block">
            <div class="image">
              <img src="../assets/fee.png" width="30" />
            </div>
            <div class="text">{{ t('payments.buyCrypto.topInfo2') }}</div>
          </div>
          <div class="info-block">
            <div class="image">
              <img src="../assets/popular.png" width="30" />
            </div>
            <div class="text">
              {{ t('payments.buyCrypto.topInfo3') }}
            </div>
          </div>
        </div>
      </div>
      <div class="selects-wrapper">
        <DepositBonusBanner
          v-if="favoriteNewDepositBonus"
          :bonus="favoriteNewDepositBonus"
          :platform="platform"
          :selected-currency-code="selectedCurrency?.code"
          :selected-currency-icon="selectedCurrency?.icon"
          type="buy"
        />
        <CurrencySelect
          v-model="currencyId"
          :platform="platform"
          :allowed-ids="allowedCurrencyIds"
          is-disabled
        />
        <NetworkSelect
          v-model="networkId"
          :networks="networks"
          :platform="platform"
          is-disabled
        />
        <div class="amount-wrapper">
          <StInput
            v-model="amount"
            inputmode="decimal"
            :label="t('payments.buyCrypto.amountLabel')"
            :placeholder="
              t('payments.buyCrypto.amountPlaceholder', {
                minAmount: formatCrypto(limits.min),
              })
            "
            :size="platform === 'mobile' ? 'm' : 'l'"
            data-t="amount"
            :error="!!amountError"
            :error-message="amountError"
            @update:model-value="onDecimalInputUpdate"
          >
            <template #postfix>
              <div class="currency">{{ SELECTED_CURRENCY_CODE }}</div>
            </template>
          </StInput>
          <div class="amount-buttons">
            <StButton
              v-for="amount in fastAmounts"
              :key="amount"
              :label="formatCrypto(amount)"
              type="gray"
              size="s"
              @click="handleAmountClick(amount)"
            />
          </div>
        </div>
      </div>
      <div class="disclaimer">
        <i18n-t keypath="payments.buyCrypto.disclaimer" tag="p">
          <template #title>
            <span class="disclaimer-title">
              {{ t('payments.buyCrypto.disclaimerTitle') }}
            </span>
          </template>
        </i18n-t>
      </div>
    </div>
    <div class="button-wrapper">
      <StButton
        :size="platform === 'desktop' ? 'xl' : 'l'"
        :label="t('payments.buyCrypto.buy')"
        class="buy-button"
        :loading="isLoading"
        :disabled="!amount"
        submit
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
// eslint-disable-next-line import/no-extraneous-dependencies
import DepositBonusBanner from '@st/bonuses/components/DepositBonusBanner/DepositBonusBanner.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDepositBonusesStore } from '@st/bonuses/stores/useDepositBonusesStore'
import { useCurrencies } from '../../../stores/currencies'
import { useCalypso } from '../../../composables/useCalypso'

const emit = defineEmits<{
  (e: 'goToNextStep', value: { frameUrl: string }): void
}>()

withDefaults(
  defineProps<{
    platform: 'mobile' | 'desktop'
  }>(),
  { platform: 'desktop' },
)

const { t } = useI18n()

const currenciesStore = useCurrencies()
const { currencies } = storeToRefs(currenciesStore)

const {
  allowedNetworkIds,
  allowedCurrencyIds,
  getLimitsByCurrencyId,
  getFastAmounts,
} = useCalypso()

const SELECTED_CURRENCY_ID = 1
const SELECTED_NETWORK_ID = 4
const SELECTED_CURRENCY_CODE = 'USDT'

const currencyId = ref(SELECTED_CURRENCY_ID)
const networkId = ref(SELECTED_NETWORK_ID)
const amount = ref('')

const selectedCurrency = computed(() =>
  currenciesStore.getCurrencyById(currencyId.value),
)

const { format: formatCrypto } = useCryptoFormatter({
  currency: computed(() => selectedCurrency.value.code),
})

const limits = computed(() => getLimitsByCurrencyId(currencyId.value))

const fastAmounts = getFastAmounts(currencyId.value, networkId.value)

function handleAmountClick(amountValue: string) {
  amount.value = amountValue
}

function onDecimalInputUpdate() {
  amount.value = amount.value.replace(',', '.')
}

const networks = computed(() => {
  const allNetworks = currencies.value[currencyId.value].networks || []

  return allNetworks.filter((network) =>
    allowedNetworkIds.value.includes(network.id),
  )
})

watch(
  currencyId,
  () => {
    networkId.value = networks.value[0]?.id
  },
  {
    immediate: true,
  },
)

const stFetch = useRawStFetch()

const isLoading = ref(false)
const amountError = ref('')

watch(amount, () => {
  amountError.value = ''
})

function validate(): boolean {
  if (
    !amount.value ||
    new Decimal(amount.value).greaterThan(limits.value.max)
  ) {
    amountError.value = t('payments.buyCrypto.maxAmountError', {
      maxAmount: formatCrypto(limits.value.max),
    })
    return false
  }

  if (new Decimal(amount.value).lessThan(limits.value.min)) {
    amountError.value = t('payments.buyCrypto.minAmountError', {
      minAmount: formatCrypto(limits.value.min),
    })
    return false
  }

  return true
}

const toast = useToast()

async function handleSubmit() {
  const isValid = validate()
  if (!isValid) return

  isLoading.value = true

  try {
    const response = await stFetch('/deposit/calypso/init', {
      method: 'post',
      body: {
        amount: amount.value,
        currencyId: currencyId.value,
        networkId: networkId.value,
      },
    })
    emit('goToNextStep', { frameUrl: response.calypsoDepositLink })
  } catch (e) {
    toast.open({
      label: t('payments.buyCrypto.somethingWentWrong'),
      type: 'negative',
    })
  } finally {
    isLoading.value = false
  }
}

const { favoriteNewDepositBonus } = storeToRefs(useDepositBonusesStore())
</script>

<style scoped>
.first-step {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  height: 100%;
  min-height: 730px;
  padding-top: var(--spacing-300);
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);
  justify-content: flex-start;

  height: 100%;
}

.selects-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  margin-top: var(--spacing-250);
}

.amount-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}

.currency {
  color: var(--text-tertiary);
}

h2 {
  margin: var(--spacing-400) 0 0;
  font: var(--desktop-text-2xl-semibold);
}

.top-info {
  display: flex;
  gap: var(--spacing-300);
  margin-top: var(--spacing-200);
  border-bottom: 1px solid var(--border-tertiary);
}

.info-block {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;

  .text {
    font: var(--desktop-text-sm-medium);
    color: var(--text-secondary);
  }
}

.disclaimer {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;

  margin-top: var(--spacing-100);
  padding-bottom: var(--spacing-250);

  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
  text-align: center;

  span {
    color: var(--text-primary);
  }
}

.disclaimer-title {
  color: var(--text-primary);
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.buy-button {
  width: 100%;
}

.amount-buttons {
  display: flex;
  gap: var(--spacing-050);
}

.first-step.mobile {
  min-height: auto;
  padding-top: 0;

  h2 {
    margin: 0;
    font: var(--mobile-title-1-semibold);
  }

  .content {
    overflow: hidden;
    overflow-y: auto;
    gap: var(--spacing-150);
    padding: var(--spacing-200) var(--spacing-200) 0;
  }

  .button-wrapper {
    padding: var(--spacing-200);
  }

  .disclaimer {
    padding-bottom: 0;
    font: var(--mobile-caption-1-regular);
  }

  .top-info {
    gap: var(--spacing-125);
    margin-top: var(--spacing-150);
    padding-bottom: 0;
    border-bottom: none;
  }

  .selects-wrapper {
    margin-top: 0;
  }

  .info-block {
    .text {
      font: var(--mobile-caption-1-regular);
    }
  }
}
</style>
